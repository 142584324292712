<template>
  <div
    class="subheader py-3 py-lg-8 subheader-transparent"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-baseline mr-5">
        <!--begin::Page Title-->
        <h2 class="subheader-title text-dark font-weight-bold my-2 mr-3">
          {{ title }}
        </h2>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-muted"
                :key="`${i}-${breadcrumb.id}`"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <div
        v-if="currentAttempt._id && !isCurrentAttempt && isSiswa"
        class="card card-custom gutter-b"
        style="position: fixed; top: 2rem; right: 2rem; z-index: 1000000"
      >
        <div class="card-header border-0 py-5">
          <h3
            class="card-title align-items-start flex-column justify-content-center"
          >
            <span class="card-label font-weight-bolder text-dark">
              {{ currentAttempt.name }}
            </span>
            <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
              currentAttempt.kurikulum.mapel.nama_mapel
            }}</span> -->
          </h3>
          <div class="card-toolbar"></div>
        </div>
        <div class="card-body d-flex flex-column">
          <div class="flex-grow-1 pb-5">
            <div class="d-flex flex-column w-100 mb-4">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="text-muted font-size-sm font-weight-bold">
                  Progress
                </span>
                <!-- <span class="text-muted mr-2 font-size-sm font-weight-bold">
                  {{
                    (
                      ((currentAttempt.soal.filter(
                        (soal) =>
                          soal.jawabans.filter((item) => item.selected).length >
                          0
                      ).length +
                        currentAttempt.soal.filter((soal) => soal.jawaban)
                          .length) /
                        currentAttempt.soal.length) *
                      100
                    ).toFixed(0)
                  }}%
                </span> -->
              </div>
              <!-- <div class="progress progress-xs w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  :style="{
                    width:
                      ((currentQuestion.question.filter(
                        (soal) =>
                          soal.jawabans.filter((item) => item.selected).length >
                          0
                      ).length +
                        currentQuestion.question.filter((soal) => soal.jawaban)
                          .length) /
                        currentQuestion.question.length) *
                        100 +
                      '%',
                  }"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div> -->
            </div>
            <div class="d-flex align-items-center">
              <!--begin::Button-->
              <a
                href="#"
                class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2 border"
              >
                <span class="svg-icon svg-icon-primary svg-icon-lg">
                  <!--begin::Svg-->
                  <inline-svg src="media/svg/icons/Home/Clock.svg" />
                  <!--end::Svg Icon-->
                </span>
                <span v-if="this.now >= this.currentAttempt.target">
                  00:00:00
                </span>
                <span v-else>
                  {{ hours | two_digits }}:{{ minutes | two_digits }}:{{
                    seconds | two_digits
                  }}
                </span>
              </a>
              <!--end::Button-->
              <!--begin::Button-->
              <router-link
                to="/attempt"
                class="btn btn-fh btn-light-info btn-hover-info border border-info font-weight-bold px-2 px-lg-5"
              >
                <!--                  <span class="svg-icon svg-icon-info svg-icon-lg">-->
                <!--                    &lt;!&ndash;begin::Svg&ndash;&gt;-->
                <!--                    <inline-svg src="media/svg/icons/General/Save.svg" />-->
                <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                <!--                  </span>-->
                Kerjakan Kembali
              </router-link>
              <!--end::Button-->
            </div>
          </div>
        </div>
      </div>
      <!--begin::Toolbar-->
      <!--      <div class="d-flex align-items-center">-->
      <!--        &lt;!&ndash;begin::Button&ndash;&gt;-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2"-->
      <!--        >-->
      <!--          <span class="svg-icon svg-icon-primary svg-icon-lg">-->
      <!--            &lt;!&ndash;begin::Svg&ndash;&gt;-->
      <!--            <inline-svg src="media/svg/icons/Communication/Add-user.svg" />-->
      <!--            &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
      <!--          </span>-->
      <!--          New Member-->
      <!--        </a>-->
      <!--        &lt;!&ndash;end::Button&ndash;&gt;-->
      <!--        &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
      <!--        <b-dropdown-->
      <!--          toggle-class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2"-->
      <!--          no-caret-->
      <!--          right-->
      <!--          no-flip-->
      <!--          v-b-tooltip="'Quick actions'"-->
      <!--        >-->
      <!--          <template v-slot:button-content>-->
      <!--            &lt;!&ndash;begin::Button&ndash;&gt;-->
      <!--            <span class="svg-icon svg-icon-primary svg-icon-lg">-->
      <!--              &lt;!&ndash;begin::Svg&ndash;&gt;-->
      <!--              <inline-svg src="media/svg/icons/Files/File.svg" />-->
      <!--              &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
      <!--            </span>-->
      <!--            New Report-->
      <!--            &lt;!&ndash;end::Button&ndash;&gt;-->
      <!--          </template>-->
      <!--          &lt;!&ndash;begin::Navigation&ndash;&gt;-->
      <!--          <div class="navi navi-hover min-w-md-250px">-->
      <!--            <b-dropdown-text tag="div" class="navi-header font-weight-bold">-->
      <!--              <span class="font-size-lg">-->
      <!--                Choose Label:-->
      <!--              </span>-->
      <!--              <i-->
      <!--                class="flaticon2-information icon-md text-muted"-->
      <!--                v-b-tooltip="'Click to learn more...'"-->
      <!--              ></i>-->
      <!--            </b-dropdown-text>-->
      <!--            <b-dropdown-text-->
      <!--              tag="div"-->
      <!--              class="navi-separator mb-3 opacity-70"-->
      <!--            ></b-dropdown-text>-->
      <!--            <b-dropdown-text tag="div" class="navi-item">-->
      <!--              <a href="#" class="navi-link">-->
      <!--                <span class="navi-text">-->
      <!--                  <span class="label label-xl label-inline label-light-primary">-->
      <!--                    Customer-->
      <!--                  </span>-->
      <!--                </span>-->
      <!--              </a>-->
      <!--            </b-dropdown-text>-->
      <!--            <b-dropdown-text tag="div" class="navi-item">-->
      <!--              <a href="#" class="navi-link">-->
      <!--                <span class="navi-text">-->
      <!--                  <span class="label label-xl label-inline label-light-danger">-->
      <!--                    Partner-->
      <!--                  </span>-->
      <!--                </span>-->
      <!--              </a>-->
      <!--            </b-dropdown-text>-->
      <!--            <b-dropdown-text tag="div" class="navi-item">-->
      <!--              <a href="#" class="navi-link">-->
      <!--                <span class="navi-text">-->
      <!--                  <span class="label label-xl label-inline label-light-warning">-->
      <!--                    Suplier-->
      <!--                  </span>-->
      <!--                </span>-->
      <!--              </a>-->
      <!--            </b-dropdown-text>-->
      <!--            <b-dropdown-text tag="div" class="navi-item">-->
      <!--              <a href="#" class="navi-link">-->
      <!--                <span class="navi-text">-->
      <!--                  <span class="label label-xl label-inline label-light-primary">-->
      <!--                    Member-->
      <!--                  </span>-->
      <!--                </span>-->
      <!--              </a>-->
      <!--            </b-dropdown-text>-->
      <!--            <b-dropdown-text tag="div" class="navi-item">-->
      <!--              <a href="#" class="navi-link">-->
      <!--                <span class="navi-text">-->
      <!--                  <span class="label label-xl label-inline label-light-dark">-->
      <!--                    Staff-->
      <!--                  </span>-->
      <!--                </span>-->
      <!--              </a>-->
      <!--            </b-dropdown-text>-->
      <!--            <b-dropdown-text-->
      <!--              tag="div"-->
      <!--              class="navi-separator mt-3 opacity-70"-->
      <!--            ></b-dropdown-text>-->
      <!--            <b-dropdown-text tag="div" class="navi-footer pt-5 pb-4">-->
      <!--              <a class="btn btn-clean font-weight-bold btn-sm" href="#">-->
      <!--                <i class="ki ki-plus icon-sm"></i>Add new</a-->
      <!--              >-->
      <!--            </b-dropdown-text>-->
      <!--          </div>-->
      <!--          &lt;!&ndash;end::Navigation&ndash;&gt;-->
      <!--        </b-dropdown>-->
      <!--        &lt;!&ndash;end::Dropdown&ndash;&gt;-->
      <!--      </div>-->
      <!--end::Toolbar-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { GRADE_ATTEMPT } from "@/core/services/store/attempt.module";
import Swal from "sweetalert2";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      interval: null,
    };
  },
  mounted() {
    if (this.now >= this.currentAttempt.target) {
      Swal.fire({
        title: "",
        text: "Waktu ujian sudah habis",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((value) => {
        if (value) {
          this.penilaian(this.currentAttempt.time);
        }
      });
      clearInterval(this.interval);
      this.dialogHabis = true;
      this.currentAttempt.elapsed_time =
        this.$options.filters.two_digits(this.hoursElapsed) +
        ":" +
        this.$options.filters.two_digits(this.minutesElapsed) +
        ":" +
        this.$options.filters.two_digits(this.secondsElapsed);
    } else {
      this.interval = window.setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000);
        if (this.now >= this.currentAttempt.target) {
          Swal.fire({
            title: "",
            text: "Waktu ujian sudah habis",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((value) => {
            if (value) {
              this.penilaian(this.currentAttempt.time);
            }
          });
          clearInterval(this.interval);
          // this.mapel.elapsedTime = this.$options.filters.two_digits(this.hoursElapsed)+':'+this.$options.filters.two_digits(this.minutesElapsed)+':'+this.$options.filters.two_digits(this.secondsElapsed)
          // localStorage.setItem('mapel', JSON.stringify(this.mapel))
          // store.commit('updateMapel', this.mapel)
        }
      }, 1000);
    }
  },
  methods: {
    penilaian(elapsed) {
      const overlay = this.$loadingOverlay.show();

      var question = [];
      this.currentQuestions.forEach((value, index) => {
        var myAnswer = [];
        var item = {};
        if (value.type != "essay") {
          value.answer
            .filter((item) => item.selected == true)
            .forEach((item) => {
              myAnswer.push(item._id);
            });

          item = {
            _id: value._id,
            answer: myAnswer,
          };
        } else {
          item = {
            _id: value._id,
            answer: value.jawaban,
          };
        }

        question.push(item);
      });

      var _data = {};

      _data._id = this.currentAttempt._id;
      _data.body = {
        remaining_time: `${elapsed}`,
        answer: question,
      };

      this.$store
        .dispatch(GRADE_ATTEMPT, _data)
        .then(() => {
          overlay.hide();
          Swal.fire({
            title: "",
            text: "Submit ujian berhasil!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.$router.replace("ujian");
        })
        .catch(() => {
          overlay.hide();

          Swal.fire({
            title: "",
            text: this.errors,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((value) => {
            if (value) {
              this.$router.push("/ujian");
            }
          });

          // this.$bvToast.toast(this.errors, {
          //   title: `Gagal Memuat Ujian`,
          //   variant: "danger",
          //   solid: true,
          // });
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentAttempt", "currentQuestions"]),
    ...mapState({
      errors: (state) => state.attempt.errors,
    }),
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.$currentUser().role == "student";
    },
    seconds() {
      return (this.currentAttempt.target - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.currentAttempt.target - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.currentAttempt.target - this.now) / 60 / 60) % 24;
    },
    isCurrentAttempt() {
      return this.$route.name == "ujian-attempt";
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
@media (min-width: 400px) and (max-width: 991.98px) {
  .aside .aside-primary {
    width: 200px !important;
  }
}
</style>

<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto w-200"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="max-height: 550px; overflow: hidden"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column mx-5" role="tablist" ref="navigation-tab">
          <!--begin::Item-->

          <li
            v-for="(item, index) in menu.filter((data) => data.show != false)"
            :key="item.to"
            class="nav-item mb-3 text-start"
            data-placement="right"
            data-container="body"
            data-boundary="window"
          >
            <template v-if="item.titleOnly">
              <span
                class="text-muted fs-5"
                style="font-weight: 500; font-size: 1rem"
                >{{ item.title.toUpperCase() }}</span
              >
            </template>
            <template v-else>
              <router-link
                :to="item.to"
                style="text-align: start !important"
                class="nav-link btn btn-clean btn-lg"
                data-toggle="tab"
                @click.native="setActiveTab"
                :data-tab="index"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="baseUrl + '/' + item.icon" />
                  <!--end::Svg Icon-->
                </span>

                <span class="text-dark-75 font-weight-bolder">
                  {{ item.name }}
                </span>
              </router-link>
            </template>
            <div
              class="separator separator-solid mt-2"
              v-if="item.separator"
            ></div>
          </li>
        </ul>
        <!--end::Nav-->
      </div>
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-sm-5 py-md-5 py-lg-10"
      >
        <KTQuickUser></KTQuickUser>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
// import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import { getMenu } from "@/core/menu.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
    };
  },
  components: {
    KTBrand,
    // KTMenu,
    // KTQuickActions,
    KTQuickUser,
    // KTQuickPanel
  },
  destroyed() {
    localStorage.removeItem("navigationTab");
  },
  mounted() {
    this.setActivePreviousTab();
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    getMenu,
    scrollToSoal(i) {
      this.$root.$emit("scrollToSoal", i);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("navigationTab")) || 0;
      const links = this.$refs["navigation-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["navigation-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");

      if (this.isMobile()) {
        document.getElementById("kt_aside_mobile_toggle").click();
        document.body.removeAttribute("data-offcanvas-aside");
        this.$refs["kt_aside"].classList.remove("aside-on");
        document.querySelector(".aside-overlay").remove();
        document
          .querySelector(".mobile-toggle-active")
          .classList.remove(".mobile-toggle-active");
      }

      // keep active tab
      localStorage.setItem("navigationTab", this.tabIndex);
    },
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentAttempt",
      "currentUser",
      "authUser",
      "currentClassSidebar",
    ]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    isSiswa() {
      return true;
    },
    menu() {
      return getMenu(this.$store.getters.currentUser.role);
    },
    baseUrl() {
      return window.location.origin;
    },
    // roleMenus() {
    //   return Menus.getMenu(currentUser.role);
    // }
  },
};
</script>
